import { Switch, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SettingsController from '../../controllers/SettingsController'
import { buttonLoadingStyles } from './Settings'

const buttonStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}
const ServicesSwap = (props) => {
  const { setSnackBarData, snackBarData, clearSnackBar } = props

  const [loading, setLoading] = useState(false)

  const [services, setServices] = useState({
    nextDay: true,
    sameDay: false,
    express: false
  })

  const handleChange = (e) => {
    setServices({
      ...services,
      [e.target.name]: e.target.checked
    })
  }

  /**
   * It sends a request to the server to update the services that are available to the user
   */
  const sentNewServicesState = async () => {
    setLoading(true)
    clearSnackBar()
    try {
      const response = await SettingsController.updateServices({
        availableServices: { ...services }
      })
      setServices({ ...response.availableServices })
      setSnackBarData({
        ...snackBarData,
        message: 'Servicios Modificados',
        type: 'success',
        show: true
      })
      setLoading(false)
      setTimeout(() => clearSnackBar(), 2500)
    } catch (err) {
      setSnackBarData({
        ...snackBarData,
        message: 'Error con la edición, intentalo más tarde',
        type: 'error',
        show: true
      })
      setLoading(false)
      setTimeout(() => clearSnackBar(), 2500)
    }
  }

  useEffect(() => {
    /**
     * It gets the services from the controller and sets the services state
     */
    const getServicesFromController = async () => {
      try {
        const response = await SettingsController.getServices()
        setServices({ ...response.availableServices })
      } catch (err) {
        console.log(err)
      }
    }
    getServicesFromController()
  }, [])

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Cambiar disponibilidad de servicios</h3>
      <div className='services_swap_styles'>
        <div style={buttonStyles}>
          <p>Express</p>
          <Switch
            checked={services.express}
            onChange={handleChange}
            color='primary'
            name='express'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
        <div style={buttonStyles}>
          <p>Sameday</p>
          <Switch
            checked={services.sameDay}
            onChange={handleChange}
            name='sameDay'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
        {/* <div style={buttonStyles}>
          <p>Nextday</p>
          <Switch
            checked={services.nextDay}
            onChange={handleChange}
            color='primary'
            name='nextDay'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div> */}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={sentNewServicesState}
            variant='contained'
            size='small'
            disabled={loading}
            style={buttonLoadingStyles(loading)}
          >
            {loading ? 'Cargando...' : 'Cambiar Servicios'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ServicesSwap
